.full_map header, .full_map footer {
  display: none;
}


.full-dialog .MuiDialog-container {
  align-items: flex-end !important;
  bottom: 0 !important;
  position: absolute !important;
  width: 100vw;
  max-height: 100vh !important;
}

.full-dialog .dialog-body {
  background-color: #FDF6EE !important; 
}

.full-dialog .MuiDialog-root .MuiPaper-root {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.MuiPaper-root {
  background-color: #FDF6EE !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; 
}

.MuiAlert-root {
  background-color: red !important;
}

.MuiSnackbar-root  {
  .MuiAlert-filledWarning {
    background-color: #ed6c02 !important;
  }
}

.dialog_footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.timing_container {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;  /* Firefox */
}

.full-dialog .dialog_padding {
  padding: 10px 6%;
}

.close_icon {
  width: 36px;
  height: 36px;
}

.flex_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-80);
}

.gap_8 {
  gap: 8px !important;
}

.switch_control_wrapper {
  width: 25%;
}

.flex_container_24 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.flex_container_90 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-90);
}

.flex_container_70 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-70);
  flex: 1 0 0;
}

.flex_container_50 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-50);
  flex: 1 0 0;
}

.flex_container_40 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-40);
  flex: 1 0 0;
}

.flex_container_30 {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-30);
}

.flex_container_20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-20);
}

.padding_70 {
  padding: var(--spacing-70);
}

.padding_50 {
  padding: var(--spacing-50);
}

.padding_40 {
  padding: var(--spacing-40);
}

.border_50 {
  border-radius: var(--spacing-50);
}

.flex_catalyst_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-60);
}

.flex_catalyst_inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-20);
  align-self: stretch;
}

.class-container.disabled {
  opacity: .5;
}

.rbc-toolbar .rbc-btn-group:last-child {
  display: none;
}

.text-justify {
  text-align: justify;
}

.corners-right {
  border-radius: 0 10px 10px 0px;
}

a, a:hover, a:visited  {
  text-decoration: none !important;
}

a:hover {
   color: var(--color-primary-50)
}

.capitalize {
  text-transform: uppercase;
}

.ant-select-dropdown, .ant-picker-dropdown {
  z-index: 1500 !important;
}

.carousel-control-prev, .carousel-control-next {
  display: none !important;
}

.submit_btn {
  background: linear-gradient(90deg, rgba(239,209,201,1) 40%, rgba(148,193,233,1) 100%);
  width: 200px;
  height: 48px;
  border-radius: 30px;
  font-size: 14px;
}

.profile_img {
  position: relative;
  width: 1px;
  height: 1px;
}

/* Dashboard - Weekly calendar start */
.fc .fc-button-primary {
  background-color: transparent;
  color: black;
}

.fc-toolbar  {
  background-color: #F2D7C5;
  padding: 10px 10px;
}

.fc-toolbar h2.fc-toolbar-title {
  font-size: 1.15em;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
}

.fc .fc-button-primary:disabled {
  background-color: transparent;
  color: #000;
  border-color: transparent;
}

.fc-view-harness a {
  color: #000;
}

.fc-scrollgrid-sync-inner a, .fc-col-header, .fc-scrollgrid i {
  font-size: .85rem;
}

.fc-scrollgrid i {
  color: #fff;
}

.fc-h-event {
  background-color: #016E2A;
}

.fc .fc-button-primary {
  border-color: transparent;
}

.rs-btn-link {
  color: var(--bg-dark-green) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--bg-dark-green) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--bg-dark-green) !important;
}

.rs-picker-toolbar-right .rs-btn-primary {
  background-color: var(--bg-dark-green) !important;
}

/* Dashboard - Weekly calendar end */

hr {
  border-top: 1px solid #ccc;
}

.btn-success {
  background-color: rgb( 25, 64, 39)
}

.btn-outline-success {
  color: rgb( 25, 64, 39);
  border-color: rgb( 25, 64, 39);
}

.btn:hover {
  background-color: rgb( 25, 64, 39);
  border-color: rgb( 25, 64, 39);
}

.App {
  text-align: center;
}

.ax-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  outline: inherit;
}

.ax-button:focus {
  outline: none;
}

.a11y {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap; 
  width: 1px !important;
}

.a11y:disabled + label {
  opacity: .7;
  cursor: default;
}

.center {
  text-align: center
}

.package-list {
  list-style: none;
}

.py-2 {
  padding: 2rem 0;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-end {
  text-align: right;
}

.app-container {
  display: flex;
  flex-direction: column;
}

@media (400px <= width <= 1000px )  {
  .app-container {
    overflow: hidden;
  }
}


@media (min-width: 641px) and (max-width: 992px) {

  .corners-right, .corners-left  {
    border-radius: 1rem;
  }
}

@media (max-width: 640px) {

  .padding_70 {
    padding: 2rem;
  }

  .padding_50 {
    padding: var(--spacing-20);
  }

  .switch_control_wrapper {
    width: 100%;
  }

  .submit_btn {
    width: 170px;
    height: 48px;
    font-size: 12px;
    font-weight: bold;
  }

  .start-here-event-container {
    top: -120px;
  }

  /* Dashboard - Weekly calendar start */
  .fc {
    display: block;
  }

  .fc .fc-toolbar-title {
    text-align: center;
    margin: 10px 0;
  }

  .fc .fc-toolbar {
    display: block;
  }

  .fc .fc-button-group {
    display: flex;
    justify-content: center;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .flex_catalyst_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-60);
  }

  .corners-right, .corners-left  {
    border-radius: 1rem;
  }
}


