.MuiTableRow-head th{
	background-color: #F2D7C5 !important;
	color: var(--color-primary-50);
}

.tss-1x5mjc5-MUIDataTable-root {
	border: 3px solid #F2D7C5;
	box-shadow: none !important;
	color: var(--color-primary-50);
}

p.css-pdct74-MuiTablePagination-selectLabel {
	margin-bottom: 0 !important;
	color: var(--color-primary-50);
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
	align-items: center;
	color: var(--color-primary-50);
}

p.MuiTablePagination-displayedRows {
	margin-bottom: 0 !important;
	color: var(--color-primary-50);
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate, .Mui-checked {
	color: var(--color-primary-50) !important;
}


.Mui-checked+.MuiSwitch-track {
	background-color: var(--color-primary-50) !important;
}
/*
table td:not(.ant-picker-cell-selected) div {
	color: var(--color-primary-50) !important;
}
*/

table td:not(.ant-picker-cell-selected).ant-picker-cell-in-view div {
  color: var(--color-primary-50) !important;
}

thead th {
	color: var(--color-primary-50) !important;
}

.ant-picker-cell-inner{
	background: none !important;
}

.datatables-noprint {
	color: var(--color-primary-50);
}

table tr.MuiTableRow-root td {
	color: var(--color-primary-50);
}

.MuiTablePagination-root, .tss-1cdcmys-MUIDataTable-responsiveBase {
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}

h6.MuiTypography-root {
	color: var(--color-primary-50);
}

.MuiCheckbox-indeterminate {
	color: var(--color-primary-50) !important;
}

p.MuiTablePagination-selectLabel {
	margin-bottom: 0px !important;
	color: var(--color-primary-50);
}