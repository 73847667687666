.subscription_img {
	border-radius: 50%;
	border: 1px solid var(--color-primary-10);
	padding: 10px;
	margin: 0 10px;
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
}

.subscription_heading {
	font-family: "Lato";
	font-size: 36px;
}

.subscription_icon {
	width: auto;
	height: 150px;
	background-size: contain;

}

.line-through{
	text-decoration: line-through;
}