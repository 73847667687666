.booked_list {
	max-height: 300px;
	overflow: auto;
	overflow-x: hidden;
	padding-right: 1rem;
}


.react-calendar__month-view__weekdays {
	margin: 20px 0;
}

.booking_details_card {
	display: flex;
	width: 100%;
	padding: var(--spacing-80);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-60);
	flex-shrink: 0;
	border-radius: var(--spacing-50);
	/*background: var(--color-neutral-100);*/
}

.booking_title {
	color: var(--color-primary-50);
	font-family: Helvetica;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 28.8px */
}

.booking_sub_title {
	color: var(--color-primary-50);
	font-family: Helvetica Neue;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 26px */
}

.show_time_grid {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: var(--spacing-30);
    width: 100%;
}

.time_conatiner {
	display: flex;
	min-width: 100px;
	min-height: 36px;
	padding: var(--spacing-40);
	justify-content: center;
	align-items: center;
	border-radius: var(--spacing-20);
	background: var(--color-secondary-90);
}

input.time_slot:disabled + label {
	background-color: var(--color-primary-100);
	color: #fff;
	opacity: .5;
}

input.time_slot:checked + .time_conatiner {
	background-color: var(--color-primary-100);
	color: #fff;
}

@media (max-width: 1024px) {
    .show_time_grid {
    	grid-template-columns: repeat(2, 1fr);
	}

	.booking_details_card {
		padding: var(--spacing-60);
	}
}