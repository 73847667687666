.faq-container-parent {
  background-color: #739e83;
  height: 813px;
  position: relative;
}

.faq-background-image {
  background-image: url("../../assets/faq-background.svg");
  height: 100vh;
  background-repeat: no-repeat;
}

.faq-title {
  font-weight: 100;
  font-size: 124px;
  position: absolute;
  top: 4%;
  color: #dad1fc;
  line-height: 6rem;
  font-family: "ArgentCF";
  text-transform: lowercase;
  font-style: italic;
  padding-left: 3%;
  width: 800px;
}
.accordion-faq {
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  position: absolute;
  width: 700px;
  top: 20%;
  right: 5%;
}

.accordion-item-title {
  color: #dad1fc;
  font-weight: 400;
  font-size: 24px;
  font-family: "ProximaNova";
  line-height: 24px;
}

.accordion-item {
  border-bottom: 1px solid #dad1fc;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}

.accordion-title:hover {
  background-color: #66ad82;
}

.accordion-content {
  padding: 0 15px;
  background-color: #dad1fc;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.accordion-content.open {
  max-height: 200px;
  padding: 15px;
}

.accordion-content p {
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.accordion-content.open p {
  opacity: 1;
}

.cta-container {
  margin-top: 5%;
  padding-left: 2%;
}

@media (400px <= width <= 1000px )  {
  .faq-container-parent {
    margin-top: 15rem;
    background-color: #f8efe8;
    height: 900px;
    width: 100%;
    margin-bottom: 10rem;
  }
  .faq-background-image {
    display: none;
  }

  .faq-title {
    color: #1d472d;
    margin-left: 1rem;
    width: 80%;
    line-height: 60px;
    font-size: 80px;
  }

  .accordion-faq {
    border-radius: 4px;
    font-family: Arial, sans-serif;
    width: auto;
    right: 0%;
  }

  .accordion-item-title {
    color: #1d472d;
    font-weight: 400;
    font-size: 25px;
    font-family: "ProximaNova";
    line-height: 45px;
  }

  .accordion-item {
    border-bottom: 0.1px solid #1d472d !important;
  }

  .accordion-title:hover {
    background-color: #66ad82;
  }

  .accordion-content {
    padding: 0 15px;
    background-color: #dad1fc;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  }

  .accordion-content.open {
    max-height: 200px;
    padding: 15px;
    background-color: #dad1fc;
  }

  .accordion-content p {
    opacity: 0;
    font-size: 30px;
    transition: opacity 0.3s ease-out;
  }

  .accordion-content.open p {
    opacity: 1;
  }
}
