.dayview_container {
	background-color: var(--bg-light-salmmon);
}

.no-class-container {
	background-color: var(--lemon-green);
	border-radius: 10px;
}

.class-container {
	background-color: var(--bg-light-salmmon);
	border-radius: 15px;
	cursor: pointer;
}

.class-container:hover {
	opacity: .8;
	transition: all 0.6s;
}

.card_service_name {
	color: var(--color-primary-100);
	font-family: "ArgentCF-Thin";
	font-size: 20px;
	font-style: normal;
	font-weight: 100;
	line-height: 120%; /* 24px */
}

@media (max-width : 667px) {

	.calendar-view {
		padding: 0 3rem;
	}

	.calendar-view h3 {
		font-size: 1.7rem;
	}

	.dayview_container {
		background-color: var(--bg-light-salmmon)
	}

	.location_text {
		color: var(--dark-green);
		font-family: Proxima Nova;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 135%; /* 27px */
		display: flex;
	}
}