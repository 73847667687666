.promo-code-modal-container {
    padding: 1rem;
}

.promo-code-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.promo-code-modal-header h2 {
    text-align: center;
}

.promo-code-modal-header>div {
    position: absolute;
    right: 10px;
    top: 10%;
}

.promo-code-modal-header img {
    width: 40px;
    cursor: pointer;
}

.promo-code-modal-type {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
}

.promo-code-modal-type>h4 {
    font-family: Argent;
    color: #245938;
    font-weight: 500;
}

.promo-code-modal-discount-container {
    display: flex;
    gap: 1rem;
}

.promo-code-modal-discount {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.promo-code-modal-discount>label {
    font-family: Lato;
    color: #245938;
}

.promo-code-body-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}


.promo-code-select>label {
    font-family: Lato;
    font-size: 14px;
    color: #245938;
}

.promo-code-modal-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.promo-code-modal-switch>span {
    font-family: Lato;
    color: #245938;
    font-size: 24px;
}

.promo-code-modal-date>label {
    font-family: Lato;
    font-size: 14px;
    color: #245938;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 35px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 30px;
    right: 0;
    bottom: 0;
    background-color: #dcdcdc;
    border-radius: 50px;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    bottom: 3px;
    background-color: #FDF6EE;
    border-radius: 50%;
    transition: 0.4s;
}


.promo-code-modal-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 1.5rem;
}

.outlined {
    all: unset;
    font-size: 20px;
    font-family: Lato;
    color: #245938;
    border: 2px #245938 solid;
    padding: 1rem;
    width: 150px;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
}

.filled {
    all: unset;
    font-size: 20px;
    font-family: Lato;
    color: white;
    background-color: #245938;
    width: 150px;
    padding: 1rem;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}