.chip, .highlightOption {
	background: var(--color-primary-50) !important;
}

.highlightOption {
	background: var(--color-primary-20) !important;
}

.multiSelectContainer li:hover {
	background: var(--color-primary-20) !important;
}

