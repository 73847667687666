.login_form {
	display: flex;
	padding: var(--spacing-80);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-30);
	align-self: stretch;

	border-radius: var(--spacing-50);
	background: var(--color-primary-100);
}


.auth-dialog.MuiDialogContent-root, .auth-dialog.MuiDialogActions-root{
	background-color: var(--color-primary-50);
}

.auth-dialog .dialog_heading {
	color: var(--color-neutral-100)
}

.rs-stack-item .rs-picker-toggle-value {
	color: var(--color-primary-50) !important;
}

.card_view_container {
	width: 240px;
	height: 190px;
	display: flex;
	padding: var(--spacing-30) var(--spacing-60);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--spacing-20);
	border-radius: var(--spacing-50);
	background: var(--color-neutral-100);
}

.card-container .card-view {
	/*background-color: #fff; /* Amit*/
	padding: 20px;
	border-radius: 10px;
	margin: 10px;
	min-width: 300px;
	min-height: 150px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1 0 1;
}

.guideline_container {
	width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr); 
}

.guideline_card {
	background: var(--color-secondary-80);
	border-radius: var(--spacing-50);
	padding: var(--spacing-30) var(--spacing-60);
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 190px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

input.plan:focus + label {
	border: 3px solid green;
}

input.plan:checked + label {
	border: 3px solid green;
}

.weekly_card {
	min-width: 140px;
	height: 20px;
	border-radius: 6px;

}

.weekly-details-calendar-container {
	display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
    justify-content: space-between;
}

.weekly_time_container label {
	padding: 1.5rem 1.5rem;
	border-radius: 8px;
}

.green_container {
	background-color: green;
}

.guideline_info .card_view, .signup .card_view {
	width: 50%;
}

.profile_img_container {
	width: 100%;
	height: auto;
	max-width: 201px;
	max-height: 201px;
	border-radius: 16px;
}

.choose_service_card {
	background: var(--color-secondary-80);
	border-radius: var(--spacing-50);
	/*padding: var(--spacing-30) var(--spacing-60);*/
	cursor: pointer;
	padding: 1rem;
	width: 250px;
	min-height: 200px;
}

@media screen and (max-width: 1024px) {
	.profile_img_container {
		width: 100%;
		height: auto;
	}
}

@media (max-width: 640px) {

	.login_form {
		
		padding: var(--spacing-50);
		gap: var(--spacing-30);
	}

	.guideline_info .card_view, .signup .card_view {
		width: 100%;	
	}

	.guideline_info p {
		font-size: 12px !important;
	}

	.guideline_container {
		display: grid;
    	grid-template-columns: repeat(1, 1fr);
    	align-items: center;
    	gap: 1rem;
	}

	.card-container .card-view {
		min-width: auto;
	}

	.profile_img_container {
		width: 100%;
		height: 100%;
		max-height: 250px;

	}


	.choose_service_card {
		width: 100%
	}

	.guideline_card {
		width: 100%;
		height: 100%;
		padding: 2rem 1rem;
	
	}
}



