.ticker-container {
    width: 100%;
    overflow: hidden;
    display: block;
  }
  
.ticker-item {
    white-space: nowrap;
    margin-right: 150px; 

    font-size: 20px;
    color: #f4f84e;
    font-family: "ProximaNova";
}
  