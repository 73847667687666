.dashboard {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
}

.list_view {
    margin: 20px 0;
    max-height: 348px;
    height: 348px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 50px;
}

.flex-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

.settings_category_container {
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}

.dashboard_profile_img {
    width: auto;
    height:200px;
    background-size: contain;
    margin: auto;
}

.report_view {
    background-image: url('../../../assets/Stats.png');
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: 100% 100%; 
    margin-bottom: 1em;
    border-radius: 8px;
}

.refresh_icon {
    position: relative;
    top: -2px;
}

.services-actions{
    display: flex;
    gap: 10px;

    .add-services {
        width: 200px;
        min-width: 200px;
        gap: 10px;
        border-radius: var(--spacing-20);
        border: 2px;

        background: #739E83;
        border: 2px solid #739E83;

        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #F4F84E;
        
        margin: 8px;
    }
}


@media (max-width: 640px) {
    .dashboard_profile_img {
        width: auto;
        height:200px;
        background-size: contain;
        margin: auto;
    }
}

@media(min-width: 641px) and (max-width: 1024px) {
    .details-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        gap: 2rem;
    }

    .list_view {
        padding-right: 7px;
    }
}

@media (max-width: 640px) {
	.dashboard {
		display: grid;
    	grid-template-columns: repeat(2, 1fr);
    	align-items: center;
    	gap: 1rem;
	}

    .flex-view {
        display: block;
    }

    .details-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        gap: 2rem;
    }

    .details-container .details_card_container {
       width: 90%;
    }

    .list_view {
        margin: 20px 0;
        max-height: 300px;
        height: 500px;
        overflow: auto;
        overflow-x: hidden;
        padding-right: 7px;
    }
}
