[role="tablist"] {
	justify-content: center;
}

.css-19kzrtu {
	padding: 0 !important;
}

.tabview .MuiBox-root {
	max-width: 100%;
    display: flex;
    /*padding: var(--spacing-50); */ /*Amit */
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-80);
}

.tabview.hidden .MuiTabs-root {
    display: none;
}

.MuiTabs-flexContainer button{
    /*
    padding-left: 50px;
    padding-right: 50px;
    */
}

.MuiTab-root {
	font-family: "ArgentCF-Thin" !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 100;
    line-height: 120%; /* 28.8px */
    color: var(--color-primary-50);
    text-transform: none !important;
}

@media (min-width: 669px) and (max-width: 1024px) {
    .MuiTabs-flexContainer {
        display: flex !important;
        justify-content: center;
    }

    .tabview .MuiBox-root {
        padding: var(--spacing-20);
        gap: var(--spacing-40);
    }

    .tabview .MuiTab-root {
        font-size: 18px !important;
    }
}

@media (max-width: 668px) {
    .MuiTabs-flexContainer {
        width: 100%;
        display: block !important; /*Amit Patch*/
        justify-content: center;
    }

    .tabview .MuiTab-root {
        font-size: 18px !important;
    }

    .tabview .MuiBox-root {
        padding: var(--spacing-20);
        gap: var(--spacing-40);
    }
}