.schedule_flex-container {
    display: flex;
    flex-wrap: wrap;  /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Space items evenly */
    gap: 10px; /* Add gap between items */
}

/* Flex item */
.schedule_flex-item {
    flex: 1; /* Allow items to grow */
    min-width: 200px; /* Minimum width for each item */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .schedule_flex-container {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }
    .schedule_flex-item {
        min-width: 100%; /* Make sure the items take full width */
    }
}