@import "rsuite/dist/rsuite.css";

.community-container {
	width: 100%;
	margin: auto;

}

.community_grid {
	padding-top: 4px;
	display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    grid-template-columns: repeat(3, 1fr);
    max-height: 1500px;
    overflow-y: auto;

}

.community_grid_item_img {
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 8px;

}

@media (min-width: 669px) and (max-width: 1023px) {
	.community_grid {
		padding-top: 30px;
	    grid-column-gap: 44px;
	    grid-row-gap: 48px;
	    grid-template-columns: repeat(2, 1fr);
	    max-height: none;
    	overflow-y: auto;
	}
}

@media (max-width: 668px) {
	.community_grid {
		padding-top: 30px;
	    grid-column-gap: 44px;
	    grid-row-gap: 48px;
	    grid-template-columns: repeat(1, 1fr);
	    max-height: none;
    	overflow-y: auto;
	}
}
