.filter_wrapper {
	border: 1px solid var(--dark-green);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;

}

.filter_container {
	max-height: 200px;
	overflow: auto;
	padding: 15px 0px;
}

.filter_section_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.dismiss_btn {
	background-color: var(--color-primary-100);

	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	color: #fff;
	height: 40px;
}


@media screen and (max-width: 696px) {
	.checkbox_container .rs-picker {
		width: 150px !important;
	}

}

@media screen and (max-width: 1200px) {
	.filter_section_container {
		display: flex;
		flex-direction: column;
	}
}

