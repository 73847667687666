.slider-parent-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
}

.slider-parent-container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.slider-parent-title {
  text-align: center;
  font-size: 15px;
  color: #315038;
  font-weight: 500;
  text-transform: uppercase;
  width: 620px;
  font-family: "ProximaNova";
  letter-spacing: 2px;
}
