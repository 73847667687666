.contact {
	background-color: #FDF6EE;
}

.contact input , .contact textarea {
    border: none;
}

.contact form {
    max-width: 80rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact form .input-box input,
.contact form .input-box select,
.contact form textarea {
    width: 100%;
    font-size: .9rem;
    color: var(--color-primary-50);
    
    /*background: var(--second-bg-color);*/
    border-radius: .8rem;
    margin: .7rem 0;
}

.contact form .input-box select, .contact form .input-box select option {
   color: var(--color-primary-50);
}

.contact form .input-box input {
    width: 49%;
}

.contact form .input-box select  {
    width: 49%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
}

.contact form select {
    width: 49%;
}

@media (max-width: 640px) {

    .contact form {
        max-width: 100rem;
        margin: 0;
        text-align: center;
        margin-bottom: 0rem;
    }

    .contact form .input-box {
        display: block;
    }

    .contact form .input-box input, .contact form .input-box select {
        width: 100%;
    }

}