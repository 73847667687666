.puchase_ticket_container {
	padding-bottom: 120px;
}

.card-container {
	display: grid;
  	grid-template-columns: repeat(1, 1fr);
  	align-items: center;
  	gap: 1.5rem;
}

.card-container .card-view {
	background-color: var(--color-secondary-80);
	padding: var(--spacing-60);
	border-radius: var(--spacing-50);
	margin: 10px;
	min-width: 300px;
	min-height: 150px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1 0 1;
}

.dialog_padding {
	padding: 10px 6%;
}

input.plan:focus + label {
	border: 3px solid green;
}

input.plan:checked + label {
	border: 3px solid green;
}

.select_plan_dropdown_container {
	width: 50%;
	margin: 2rem 0;
}


.react-dropdown-select-item-selected {
  background-color: var(--color-primary-50) !important;
}

.payment_left_view {
	width: 90%;
	display: flex;
	padding: var(--spacing-70);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-70);
	flex: 1 0 0;
	border-radius: var(--spacing-50);
	background: var(--color-neutral-100);
	margin: 0 auto;
}

.payment_card_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--space-50);
	align-self: stretch;
}

.payment_right_view {
	width: 10%;
	display: flex;
	/*padding: var(--spacing-20);*/
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-20);
	flex: 1 0 0;
	margin: 0 auto;
	
}

.payment_right_view .coupon {
	background: var(--color-neutral-100);
}

.payment {
	gap: 24px;
	display: flex;
	justify-content: space-between;
}

.weekly_card {
	min-width: 140px;
	height: 20px;
	border-radius: 6px;
}

.weekly-details-calendar-container {
	display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
    justify-content: space-between;
}

.weekly_time_container label {
	padding: 1.5rem 1.5rem;
	border-radius: 8px;
}

.green_container {
	background-color: green;
}

.count_container .number_container {
	margin: 0 3rem;
}

.ticket_view .card_view {
	width: 25%;
}

.time_remaining_container {
	background-color: #F2D7C5;
}

.confirmation_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-80);
	width: 75%;
}

.confirmation_container .card_item {
	background-color: var(--color-accent-100);
	border-radius: var(--spacing-50);
	padding: 3rem;
}

.confirmation_container .card_item_content {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-30);
}

.confirmation_container .quantity {
	color: var(--color-neutral-90);
	font-family: Helvetica Neue;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 26px */
}

.confirmation_container .price {
	color: var(--color-neutral-90);
	text-align: right;
	font-family: Helvetica;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
}

@media screen and (max-width: 980px) {
	.payment {
		gap: 24px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.payment_right_view {
		width: 90%;
		margin: 0 auto;
	}
}

@media (max-width: 640px) {

	.confirmation_container {
		width: 100%;
	}

	.card-container .card-view {
		min-width: 150px;
	}

	.react-dropdown-select-dropdown {
		max-width: 70px; 
	}

	/*.card-container .card-view div {
		display: block !important;
		width: 100%;
	}*/

	.count_container {
		justify-content: space-between;
		
	}

	.count_container .number_container {
		margin: 0 0rem;
	}

	.ticket_view .card_view {
		width: 100%;
	}

	.dialog_padding {
		padding: 10px 1%;
	}

	.card-container {
		display: block;
		margin: auto;
	}

	.payment_left_view {
		width: 100%;
		display: flex;
		padding: var(--spacing-40);
		flex-direction: column;
		align-items: flex-start;
		gap: var(--spacing-20);
		flex: 1 0 0;
		border-radius: var(--spacing-50);
		background: var(--color-neutral-100);
		margin: auto;
	}

	.payment_card_container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: var(--space-50);
		align-self: stretch;
	}

	.payment_right_view {
		width: 100%;
		display: flex;
		padding: var(--spacing-20);
		flex-direction: column;
		align-items: flex-start;
		gap: var(--spacing-20);
		flex: 1 0 0;
		
	}

	.payment {
		gap: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}



