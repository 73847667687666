.payment-save-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f0e7;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: "Lato";
  font-size: 14px;
  line-height: 1.5;
  color: #3d4932;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-save-text {
  flex: 1;
  margin-right: 16px;
  font-family: Lato;
  color: #245938;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dcdcdc;
  border-radius: 34px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #345e3f;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.time-remaining-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9e2d4;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #2c2c2c;
  margin: 20px auto;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-label {
  font-weight: 400;
}

.time-value {
  font-weight: 600;
}
