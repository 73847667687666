.user_profile_icon {
	width: 36px;
	height: 36px;
	background-position: contain;
	position: relative;
	top: 7px;
}

.book_now {
  color: #fff;
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #F2D7C5;
  border-radius: 10px;
  color: #4E705A;
}

.catalyst-details {
	/*font-size: 24px;*/
}

.catalyst-details-image-container {
	padding: 20rem 0;
	background-color: rgb(25, 64, 39);
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100vw;
	height: 55vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.catalyst_service_details_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.feature_container {
	margin-top: -2%;
}

.learn_more {
	min-width: 140px !important;
}


.catalyst-details-feature-card-container {
	display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
    gap: 4rem;
    justify-content: space-between;
}

.catalyst_details_profile_img {
	height: 400px;
	width: 80%;
	margin-left: 20%;
	object-fit: contain;
	border-radius: 12px;
	
	position: relative;
}

.catalyst_details_profile_img img {
	object-fit: cover;
	border-radius: 12px;
	width: 100%;
	height: 100%;
}

.catalyst-image {
	position: absolute;
	bottom: -100px;
	right: 10%;
	width: 350px;
	height: 350px;
	border-radius: 50%;
}

.catalyst-details-about-container {
	padding: 0rem 0;
	font-size: 16px;
	text-align: justify;
}

.catalyst-details-location-card-container {
	margin: 1rem 0;
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.catalyst-details-location-card {
	background-color: var(--bg-salmmon);
	display: flex;
	color: var(--bg-dark-green);
	align-items: center;
	font-size: 1.1rem;
    word-wrap: normal;
    justify-content: center;
    background-color: var(--bg-light-salmmon);
	border-radius: 12px;
	padding: .5rem 1rem;
	min-width: 100px;
	min-height: 80px;
}

.catalyst-details-calendar-container {
	display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0rem;
    justify-content: space-between;
    overflow-x: auto;
    max-height: 1300px;
    overflow-y: auto;
    
}

.month-container {
	background-color: #193F27;
	color: #fff;
}

.session-info {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
}

.session-info-end {
	display: flex;
	justify-content: end;
	align-items: center;
	text-align: center;
}

.best-offer-container {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: space-between;
}

.catalyst_Home_grid_item_img {
	width: 100%;
    height: 220px;
    object-fit: cover;
}

@media (max-width: 1366px) {
	.catalyst_details_profile_img {
		height: 250px;
		width: 250px;
		margin: 0 auto;
	}

	.catalyst_details_profile_img img {
		top: -60px;
	}
}

@media (min-width : 669px) and (max-width : 1024px)  {

	.catalyst_details_profile_img {
		height: 250px;
		width: 250px;
		
	}

	.catalyst_details_profile_img img {
		width: 100%;
		height: 100%;
		top: -50px;
	}
}

@media (max-width: 982px) {
	.catalyst-details-calendar-container {
		grid-template-columns: repeat(1, 1fr);
	}

	.catalyst-details-feature-container {
		top: 0;
	}

	.catalyst_details_profile_img {
		height: 100%;
		width: 100%;
		background-size: contain;
		object-fit: cover;
		top: 0;
		position: static;
		margin: 2rem auto;
	}

	.catalyst_details_profile_img img {
		width: 100%;
		height: 100%;
		top: 0px;
	}
}

@media (max-width : 668px) {
	.catalyst_details_profile_img {
		height: 100%;
		width: 100%;
		background-size: contain;
		
		padding: 0px;
		
	}

	.catalyst-image {
		bottom: 0;
		width: 250px;
		height: 250px;
		display: flex;
		justify-content: center;
		right: 0;
		position: static;
		margin: auto;
	}

	.catalyst-details h1 {
		font-size: 32px;
	}

	.catalyst-details-location-card-container {
		grid-template-columns: repeat(2, 1fr);
	}

	.catalyst-details-location-card {
		padding: 1rem;
		justify-content: flex-start;
	}

	.catalyst-details-calendar-container {
		grid-template-columns: repeat(1, 1fr);
		max-height: none;
	}

	.catalyst-details-feature-card-container {
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
	}

	.book_now {
		padding: 5px 10px; 
	}

	.session-info {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 1.2rem;
	}

	.session-info-end {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: left;
	}

	.catalyst_details_content_bg {
		background-image: url("../../assets/catalyst_details_content_bg.png");
		background-size: auto;
		background-repeat: no-repeat;
		text-overflow: hidden;
		position: relative;
		margin-top: 0px;
	}

	.catalyst_details_content_container {
		text-align: left;
		/*position: absolute;*/
		bottom: 30px;
		padding: 30% 24px 10px 24px;
		text-overflow: hidden;
		color: #fff;
		height: 100%;
		text-align: left;
	}

	.best-offer-container {
		display: grid;
    	grid-template-columns: repeat(1, 1fr);
    	gap: 2rem;
    	justify-content: space-between;
	}
}

@media (max-width : 498px) {

.catalyst_service_details_wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}
}
