.btn-group {
	background-color: var(--dark-green);
	color: #fff;
	display: flex !important;
}

.btn-group .btn {
	color: #fff;
	border: none;
	font-size: 12px;
	transition: all 0.3s ease;
	padding: 8px 15px;
	opacity: .8;
	margin: 2px;
}

.btn-group .btn:hover {
	color: #fff !important;
	border: none;
}

.btn-check:checked + .btn {
	background-color: #fff !important;
	color: var(--color-primary-50) !important;
	border: none;
	transition: all 1s ease;
	border-radius: 6px !important;
	opacity: 1;
}

.filter_group {
	background-color: transparent !important;
	color: var(--dark-green);
}

.filter_group .btn, .filter_group .btn:hover {
	color: var(--dark-green);
	border-radius: 8px;
	border: 1px solid var(--dark-green);
	opacity: 1;
}