.swiper {
  width: 100%;
  height: 100%;
  font-size: .8em;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  text-align: left;
  cursor: pointer;
}

.swiper-scrollbar-drag {
  background-color: var(--dark-green) !important;
}

.swiper-slide {  height: auto; }

.swiper-slide img {
  display: block;
  width: 220px;
  height: 200px;
  border-radius: 20px;
  object-fit: cover;
 
}

.slide_img_container {
  display: block;
  width: 100%;
  margin: auto;
  height: auto;
  height: 340px;
  border-radius: 15px;
}

.slide_img_container img {
  background-size: contain;
  width: 75%;
  height: auto;
  margin: auto;
}

.slide_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-60);
}
/*
.swiper-button-next, .swiper-button-prev {
  color: #fff;
  background-color: #0D0D0D;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  opacity: .9;
}
*/

.swiper-container {
  width: 480px;
}

@media screen and (max-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media screen and (max-width: 640px) {
  .swiper-container {
    width: 640px;
  }

  .slide_img_container {
    background-size: contain;
    height: 220px;
  }

  .slide_img_container img {
    width: 220px;
    height: 220px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50% !important;
    margin: 0 auto;
    overflow: hidden;
  }

   
}