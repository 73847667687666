ul li {
	font-family: "Lato";
}

.card-container .card-view {
	background-color: var(--color-secondary-80);
	padding: var(--spacing-60);
	border-radius: var(--spacing-50);
	margin: 10px;
	min-width: 300px;
	min-height: 150px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1 0 1;
}

input[type="radio"] {
	accent-color: var(--dark-green);
}

input.plan:focus + label {
	border: 3px solid green;
}

input.plan:checked + label {
	border: 3px solid green;
}

input.participant + label {
	color: var(--color-primary-50);
}

input.participant:checked + label {
	background-color: var(--dark-green);
	color: #fff;
	border-radius: 8px;
}

input.weekday:checked + label {
	background-color: var(--dark-green);
	color: #fff;
	border-radius: 8px;
}

.weekly_card {
	min-width: 140px;
	height: 20px;
	border-radius: 6px;
}

.weekly-details-calendar-container {
	display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
    justify-content: space-between;
}

.weekly_time_container label {
	padding: 1.5rem 1.5rem;
	border-radius: 8px;
}

.green_container {
	background-color: green;
}

.count_container .number_container {
	margin: 0 3rem;
}

.ticket_view .card_view {
	width: 25%;
}

.time_remaining_container {
	background-color: #F2D7C5;
}

.image-container {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: space-between;
}

.participants_radio_container {
	display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: .1rem;
    
}

.week-list {
	display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    gap: .1rem;
    justify-items: center;
}

@media  (min-width: 769px) and (max-width: 1380px) {
	.week-list {
		display: grid;
    	grid-template-columns: repeat(5, 1fr);
    	gap: .5rem;
	}

	.participants_radio_container {
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
	}
}

@media  (min-width: 640px) and (max-width: 768px) {
	.card-container .card-view {
		min-width: 80px;
	}

	.week-list {
		display: grid;
    	grid-template-columns: repeat(5, 1fr);
    	gap: .5rem;
	}
}

@media (max-width: 640px) {
	.card-container .card-view {
		min-width: 150px;
	}

	.card-container .card-view div {
		/*display: block !important;
		width: 100%;*/
	}

	.count_container {
		justify-content: space-between;
		
	}

	.count_container .number_container {
		margin: 0 0rem;
	}

	.ticket_view .card_view {
		width: 100%;
	}

	.week-list {
		display: grid;
    	grid-template-columns: repeat(4, 1fr);
    	gap: .3rem;
    	
	}

	.participants_radio_container {
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
	}

}
