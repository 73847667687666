.upload_img_container {
	display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.img-wrap {
	position: relative;
}

.img-wrap img {
	width: 150px;
	height: 150px;
	background-size: contain;
	padding: 3px;
	border-radius: 16px;
}

.img-wrap .image_close_icon {
	position: absolute;
	right: 0;
	top: -5px;
	padding: 10px;
}

.img-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2rem;
    justify-items: center;
}

@media screen and (max-width: 660px) {
	.upload_img_container {
		flex-direction: column;
	}

	.img-container {
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}

}