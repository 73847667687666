.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-primary-50);
  margin: 0 auto;
}

.ant-picker, .ant-picker:focus {
	border : none !important;
	border-bottom: 1.5px solid var(--color-primary-50) !important;
	color: var(--color-primary-10);
	border-radius: 0 !important;
	outline: none !important;
	box-shadow: none !important;
}

.ant-picker-cell-selected {
	background: var(--color-primary-50) !important;
	color: #fff !important;
}

.react-calendar__navigation {
	display: flex;
}

.react-calendar button {
	background-color: transparent;
	text-align: center;
	padding: 5px;
}

.react-calendar button:hover {
	background-color: #ccc;
	color: #fff;
	border-radius: 7px;
}

.react-calendar__month-view__weekdays {
	text-align: center;
}

.react-calendar__tile--active {
	background-color: var(--deep-green) !important;
	color: #fff;
	border-radius: 7px;
}

.react-calendar__month-view__weekdays__weekday abbr {
	color: var(--color-primary-50) !important;
	text-transform: uppercase;
}


.react-calendar__navigation__label__labelText, .react-calendar__navigation__arrow {
	color: var(--color-primary-50) !important;
	text-transform: uppercase;
	font-size: 1.8rem !important;
}

button:disabled abbr{
	opacity: .3 !important;
}

