.home {
    padding: 0px 0;
    z-index: 1000;
}

.home-img img {
    width: 35vw;
    animation: floatImage 4s ease-in-out infinite;
}

.home-content {
    height: calc(100vh - 80px);
   /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    */
}



