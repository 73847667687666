.catalyst_grid {
	display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 48px;
    grid-row-gap: 3rem;
    grid-template-columns: repeat(4, 1fr); 
}

.catalyst_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-60);
	justify-content: center;
	cursor: pointer;
	white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; 
    width: 100%;
}


.catalyst_container_content_wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-20);
}

.catalyst_container_content_wrapper div {
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}

.catalyst_grid_item_img {
	width: 220px;
	height: 220px;
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 50% !important;
	margin: 0 auto;
	overflow: hidden;
}

.home_card_sub_text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.catalyst_title {
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto;
    text-align: center;
}

.catalyst:hover {
	cursor: pointer;
}

@media (min-width: 993px) and (max-width: 1366px) {
	.catalyst_grid {
	    grid-template-columns: repeat(3, 1fr); 
	}
}

@media (max-width: 992px) {
	.catalyst_grid {
	    grid-template-columns: repeat(2, 1fr); 
	}
}

@media (max-width: 600px) {
	.catalyst_grid_item_img {
		height: 220px;
		width: 220px;
		margin : 0 auto;
		border-radius: 0;
	}

	.catalyst_grid {
	    grid-template-columns: repeat(1, 1fr); 
	}
}