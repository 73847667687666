.auth-dialog.MuiDialogContent-root {
	margin-bottom: 90px; /*Adding margin bottom to adjust the dialog footer height*/
}

.login_container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-60);
}

.auth_container {
 	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-60);
	justify-content: center;
	cursor: pointer;
    width: 100%;
}

.auth_card_container {
 	display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 6rem;
    justify-items: center;
}

.login input[type="radio"] {
	display: flex;
	width: 220px;
	height: 190px;
	padding: var(--spacing-30) var(--spacing-60);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--spacing-20);
	border-radius: var(--spacing-50);
}

.login input[type="radio"]:checked + label {
	background-color: yellow !!important;
	background: var(--color-accent-90);
}

.otp-details {
	font-size: .8em;
	font-family: roboto, arial, sans-serif;
}

.otp-field {
	width: 14% !important;
	margin: 1% !important;
	height: 40px !important;
	padding: 12px 18px !important;
	display: inline-block !important;
	background: transparent;
	outline: none;
	border: none !important;
	border-radius: 0 !important;
	border-bottom: 2px solid #fff !important;
	font-weight: bold;
	text-align: center;
	font-size: 24px;
}

.login_form label {
	color: #fff;
}

.category_card {
	background: var(--color-secondary-80);
	border-radius: var(--spacing-50);
	/*padding: var(--spacing-30) var(--spacing-60);*/
	cursor: pointer;
	padding: 1rem;
	width: 250px;
	min-height: 200px;
}

.auth_card {
	background: var(--color-secondary-80);
	border-radius: var(--spacing-50);
	/*padding: var(--spacing-30) var(--spacing-60);*/
	cursor: pointer;
	padding: 1rem;
	width: 250px;
	min-height: 180px;
}

.login_form .MuiFormLabel-root, .login_form input {
	color: #fff !important;
}

.login_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
	border-bottom: 2px solid #fff !important;
}

.login_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before, .login_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
	border-bottom: 2px solid #fff !important;
}


.auth_card_container {
 	display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: .5rem;
    justify-items: center;
}

.join_now_btn {
	color: var(--color-accent-90);
}

@media (min-width: 821px) and (max-width: 1367px) {
	.category_card {
		gap: .2rem;
		width: 150px;
		height: 150px;
	}
} 

@media (min-width: 641px) and (max-width: 820px) {
	.category_card {
		gap: .2rem;
		width: 200px;
		height: 200px;
	}
}

@media (max-width: 640px) {
	.auth_card_container {
	    grid-template-columns: repeat(1, 1fr);
	    gap: .2rem;
	}

	.category_card {
		padding: 1rem 1rem;
		width: 100%;
		height: auto;
	}

	.auth_card {
		background: var(--color-secondary-80);
		border-radius: var(--spacing-50);
		/*padding: var(--spacing-30) var(--spacing-60);*/
		cursor: pointer;
		padding: 1rem;
		width: 250px;
		min-height: 150px;
	}

	.otp-field {
		padding: 12px 1px !important;
	}
}