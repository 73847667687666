@import "rsuite/dist/rsuite.css";

.rs-btn-subtle {
	border: 1px solid green !important;
}

.checkbox_container {
	position: relative;
}

.checkbox_container .rs-picker-value-list {
	display: none !important
}

.rs-picker-menu {
    background-color: var(--rs-body) !important;
    z-index: 0;
}

.value {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-items: center;
	top: -2px;
	color: var(--color-primary-50);
	font-weight: bold;
	z-index: 5;
}

.rs-picker-toggle-placeholder {
	opacity: 0;
}

.rs-checkbox-wrapper:before, .rs-checkbox-wrapper .rs-checkbox-inner:before {
    border: 1px solid var(--deep-green) !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: var(--deep-green) !important;
}

.rs-picker-value-count {
    background-color: var(--deep-green) !important;
    font-size: 10px;
}

.rs-checkbox-checker label {
	color: var(--color-primary-50);
}