.about-grid {
	display: grid;
  	/*grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)); /* Adjust min and max width as needed */
  	grid-template-columns: repeat(2, minmax(45%, 1fr)); /* Adjust min and max width as needed */
  	gap: 3rem; /* Adjust gap between cards as needed */
  	width: 100%;
  	overflow: hidden;
  	margin: auto;
}


.each_about_card, .about-grid img {
	border-radius: 1rem;
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-size: contain;
}

.each_about_card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 100%;
	min-height: 100%;
}

.about_ready_container {
	width: 70%;
}

.about-card {
	background-color: #B67B22;
}

.about-card-odd {
	background-color: #DBD1FF;
}

.about-journey {
	text-align: center;
	font-size: 1.8em;
	padding: 5rem 10rem;
}

@media screen and (max-width: 1025px) {
	.about-journey {
		padding: 0 10%;
	}

	.each_about_card {
		min-width: 100%;
		height: 510px;
	}

	.about-grid {
		grid-template-columns: repeat(1, minmax(100%, 1fr));
	}

}

@media screen and (max-width: 600px) {
	.about-grid {
		grid-template-columns: repeat(1, minmax(100%, 1fr));
	}

	.each_about_card {
		min-width: 100%;
		min-height: 315px;
		height: 315px;
	}
}