.become_bg_1 {
    min-width: 57%;
    background-image: url('../../assets/become_catalyst_bg_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;   
    padding: 48px 15% 48px 48px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-60);
    color: #fff;
    height: 690px;
}

.become_bg_2 {
    min-width: 67%;
    background-image: url('../../assets/become_catalyst_bg_2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: auto;
    left: -24%;
    position: relative; 
    height: 690px; 
}

.become_catalyst_flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--spacing-60);
    align-self: stretch;
    flex-wrap: wrap;
}

.become_catalyst_flex .card_item {
    border-radius: var(--spacing-20);
    border: 2px solid var(--color-primary-50);
    background: var(--color-secondary-90);
    padding: var(--spacing-50);
}


.ssZ_HbY {
    font: var(--plan-title-font);
    text-decoration: var(--plan-title-font-text-decoration);
    color: rgb(var(--plan-title-color));
    padding-bottom: 12px;
    line-height: 1.5!important;
}

.stpEesK {
    font: var(--plan-price-font);
    text-decoration: var(--plan-price-font-text-decoration);
    color: rgb(var(--plan-price-color));
    line-height: 1!important;
}

ul.none {
    list-style: none;
}

.surW0GO {
    font-size: 18px!important;
    line-height: 1!important;
    margin-right: 2px;
    display: inline-block;
    vertical-align: top;
}

.s_gqM8G {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-auto-rows: 1fr;
    gap: 4rem;


}

.s_gqM8G > li {
    position: relative;
    box-sizing: border-box;
    
    padding: 0;
    margin: 0;
    /*max-width: 454px;*/
    width: 100%;
    grid-auto-rows: 1fr;
}

.spCnnvH.on_JcMc--disabled {
    cursor: default
}

.spCnnvH[disabled] {
    pointer-events: none
}


.sZtzJ2B {
    padding-top: calc(var(--vertical-padding)*1px);
    padding-bottom: calc(var(--vertical-padding)*1px);
    padding-left: calc(var(--horizontal-padding)*1px);
    padding-right: calc(var(--horizontal-padding)*1px);
    position: relative;
}

.sFkuq_5 {
    background-color: #F8F3F6;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 30px;
}

.sqxxbos.oHUVJdh--highlighted {
    background-color: rgb(var(--primary-benefits-base-bg-color));
    color: rgb(var(--plan-benefits-color-highlighted));
    border-top-color: rgb(var(--card-border-color-highlighted));
}

.sqxxbos {
    display: block;
    
    font: var(--plan-benefits-font);
    text-decoration: var(--plan-benefits-font-text-decoration);
    color: rgb(var(--plan-benefits-color));
    flex: 1 1 auto;
    border-top-width: calc(var(--card-border-width)*1px);
    border-top-color: rgb(var(--card-border-color));
    border-top-style: solid;
    padding-top: calc(var(--vertical-padding)*1px);
    padding-bottom: calc(var(--vertical-padding)*1px);
    padding-left: calc(var(--horizontal-padding)*1px);
    padding-right: calc(var(--horizontal-padding)*1px);
}

.sqxxbos li {
    line-height: 2.1;
    display: flex;
}

.sqxxbos li i {
    padding-right: 10px;
    padding-top: 9px;
    color: green;
}

.sZtzJ2B.okRFjvD---textAlignment-6-center {
    text-align: center;
}

.s__2rf_yC {
    margin-top: 12px;
    height: 40px;
}

.s__2rf_yC div {
    font: var(--plan-tagline-font);
    text-decoration: var(--plan-tagline-font-text-decoration);
    color: rgb(var(--plan-tagline-color));
    font-size: 14px!important;
    line-height: 20px!important;
}

.monthly_plan, .annual_plan {
    text-align: center;
}

@media screen and (min-width : 641px) and (max-width: 1024px) {
    .s_gqM8G {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        gap: 4rem;
        padding-left: 0 !important;
    }

    .become_bg_1 {
        padding: 48px 15% 48px 24px;
    }
}

@media (max-width : 640px) {

    .become_catalyst_flex .card_item {
        padding: .7rem;
        min-width: 46%;
        text-align: center;
    }

    .become_catalyst h1 {
        font-size: 3rem;
    }

    .become_catalyst p {
        font-size: 18px;
        line-height: 1.5em;
        text-align: justify;
    }

    .become_catalyst_info {
        padding: 5rem 2%;
        line-height: 1.6;
    }

   .become_catalyst h1 {
        font-size: 3rem;
    }

    .become_catalyst p {
        font-size: 18px;
        line-height: 1.5em;
        text-align: justify;
    }

    .become_catalyst_info {
        padding: 5rem 2%;
        line-height: 1.6;
    }

    .s_gqM8G  {
        display: block;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        gap: 4rem;
        padding-left: 0 !important;
        
    }

    .sqxxbos {
        padding-right: 30px !important;
    }

    .become_bg_1 {
        width: 100%;
        background-color: var(--color-primary-50);
        border-radius: 8px;
        padding: 2rem;
        height: 500px;
    }

    .become_bg_1 h1, .become_bg_1 h5 {
        text-align: center;
    }

    .become_bg_2 {
        display: none;
    }

    .apply_now_container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .become_catalyst_flex {
        gap: var(--spacing-20);
    }
}

