.MuiInputLabel-formControl {
	color: var(--color-primary-10) !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before , .css-v4u5dn-MuiInputBase-root-MuiInput-root:after, .MuiInputBase-root:before,  .MuiInputBase-root:after {
	border-bottom: 2px solid var(--color-primary-10) !important;
}

.MuiInputBase-input {
	color: var(--color-primary-50) !important;
}

textarea  {
	padding: 10px;
	border-radius: 5px !important;
	border: 2px solid var(--color-primary-10) !important;
	color: var(--color-primary-50) !important;
	
}

ul.MuiList-root li, .ant-picker-content li div, .ant-picker-input input {
	color: var(--color-primary-50) !important;
}

.css-t3ipsp-control:hover, .css-t3ipsp-control:focus, .css-t3ipsp-control, .css-13cymwt-control {
	border-color: var(--color-primary-10) !important;
	box-shadow: 0 0 1px var(--color-primary-10) !important;
}

.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
	-webkit-box-shadow: 0 0 0 3px var(--color-primary-10) !important;
	box-shadow : 0 0 0 1px var(--color-primary-10) !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
	border-color: var(--rs-input-focus-border);
}

.rs-picker-toggle-textbox, .rs-picker-default .rs-picker-toggle {
	border: 1px solid var(--color-primary-10) !important;
}


.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
	border-bottom: 1px solid var(--color-primary-10) !important;
}