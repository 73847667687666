.close {
    background-image: url("../../assets/Cancel_white.png") !important;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    position: relative;
    left: 20px;
    top: -6px;
    transform: scale(1);
}

.close::before {
    content: "" !important;
}

.header {
    top: 0;
    left: 0;
    width: 100vw;
    padding: 30px 8.2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    z-index: 1000;
    width: 100%;
    height: 80px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.navLink-container{
    width: 550px;
    display: flex;
    align-items: center;
    position: relative;
}

.ul-container {
    list-style: none;
    position: absolute;
    background-color: #fff;
    border-radius: 15px;
    width: 250px;
    height: 130px;
    padding: 2%;
}

.link-item {
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.2rem !important;

}

.link-item:hover {
    background-color: var(--color-primary-10);
    border-radius: 15px;
    color: white !important;
    cursor: pointer;
}

li a {
    color: var(--color-primary-100);
}

li a:hover {
    color: white !important;
    cursor: pointer;
}

.selected-item {
    background-color: var(--color-primary-50);
    border-radius: 15px;
}

.selected-item-link {
    color: white;
}

a.active {
    border-bottom: 2px solid var(--color-primary-50);
    padding: 4px 0;
}

a.color-accent-90.active {
    border-bottom: 2px solid var(--color-accent-90);
    padding: 4px 0;
}

a.white_text:focus, a.white_text:hover {
    color: var(--color-neutral-100);
}

a.color-primary-100:focus, a.color-primary-100:hover {
    color: var(--color-primary-100);
}

a.color-accent-90:focus, a.color-accent-90:hover {
    color: var(--color-accent-90);
}

a.white_text.active {
    border-bottom: 2px solid var(--color-neutral-100);
    padding: 4px 0;
}

.fixed {
    position: fixed;
}

.sticky.fixed {
    background-color: var(--color-primary-50);
}

nav a:active, nav a:hover, nav a:focus {
    color: inherit;
}

.sticky {
    position: fixed;
    align-items: center;
}

.homeNavBar {
    position: fixed;
}

.eventHeaderBgColor {
    background-color: var(--pastel-green);
}

.eventNavBar {
    background-image: url("../../assets/event_nav_bg.png");
    height: 35vh;
    background-size: 100% 100%;
}

.newsHeaderBgColor {
    background-color: var(--bg-lilac);
}

.catalystsHeaderBgColor {
    background-color: var(--ocre);
}

.catalystDetailsHeaderBgColor {
    background-color: var(--bg-dark-green);
}

.aboutHeaderBgColor  {
    background-color: var(--rs-body);
}

.contactHeaderBgColor  {
    background-color: var(--bg-dark-green);
}

.newsNavBar {
    background-image: url("../../assets/news_nav_bg.png");
    height: 35vh;
    background-size: 100% 100%;
}

.catalystsNavBar {
    background-image: url("../../assets/catalyst_details_bg.png");
    height: 35vh;
    background-size: 100% 100%;
}

.catalystDetailsNavBar {
    background-image: url("../../assets/catalyst_details_bg.png");
    height: 35vh;
    background-size: 100% 100%;
}

.aboutNavBar {
    background-image: transparent;
    height: 20vh;
    background-size: 100% 100%;
}

.contactNavBar {
    background-image: url("../../assets/catalyst_details_bg.png");
    height: 35vh;
    background-size: 100% 100%;
}

.header.sticky {
    /*border-bottom: .1rem solid rgba(0,0,0,.2);*/
    height: 80px;
    transition: background .5s ease-out;
    width: 100vw;
}

.logo {
    font-size: 2.5rem;
    font-weight: 600;
    cursor: pointer;
}

.navbar1 a {
    margin: 0 1rem;
}

.navbar1 a:hover,
.navbar1 a.active {
    /*color: var(--main-color);*/
}

#menu-icon {
    /*color: var(--text-color);*/
    display: none;
    font-size: 1.5rem;
}

.desktop-login-btn {
    display: block;
    font-size: 1.7rem !important;
}

.mobile-login-btn {
    display: none;
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1366px) {
    .eventNavBar, .newsNavBar, .catalystsNavBar, .catalystDetailsNavBar, .aboutNavBar, .contactNavBar {
        height: 45vh;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
    .eventNavBar, .newsNavBar, .catalystsNavBar, .catalystDetailsNavBar, .aboutNavBar, .contactNavBar {
        height: 35vh;
    }
}

@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {

}

@media (min-device-width: 641px) and (max-device-width: 1024px) {
    .eventNavBar, .newsNavBar, .catalystsNavBar, .catalystDetailsNavBar, .contactNavBar {
        height: 32vh;
    }
  
    #menu-icon {
        display: block;
    }

    .bx-menu {
        z-index: 999;
        margin-left: 15px;
    }

    .header {
        align-items: center;
        justify-content: space-between;
    }

    .navbar1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-dark-green);
        border-top: none;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
        display: none;
    }

    .navbar1.active {
        width: 100vw;
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100vh;
        z-index: 999;
        position: absolute;
        overflow-y: auto;
        top: 0;
        left: 0;
    }

    .navbar1 a {
        display: block;
        font-size: 1rem;
        margin: 1rem 0;
        color: #fff;
    }

    .active {
        border-bottom: none;
    }

    .desktop-login-btn {
        display: none;
    }

    .mobile-login-btn {
        display: block;
        font-size: 2.5rem !important;
    }

    .close {
        top: 12px;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {

}

@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {

    #menu-icon {
        display: block;
        z-index: 1000;
    }

    .navbar1 {
        padding: 1rem 5%;
        background: var(--bg-dark-green);
        padding-top: 0rem;
        display: none;
    }

    .navbar1.active {
        width: 100vw;
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100vh;
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: auto;
    }

    .navbar1 a {
        display: block;
        font-size: 1rem;
        margin: 1rem 0;
        color: #fff;
    }

    .active {
        border-bottom: none;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    #menu-icon {
        display: block;
        z-index: 1000
    }

    .navbar1 {
        padding: 1rem 5%;
        background: var(--bg-dark-green);
        padding-top: 0rem;
        display: none;
    }

    .navbar1.active {
        width: 100vw;
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100vh;
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: auto;
    }

    .navbar1 a {
        display: block;
        font-size: 1rem;
        margin: 1rem 0;
        color: #fff;
    }

    .active {
        border-bottom: none;
    }
}

@media screen and (max-width: 480px) {

    #menu-icon {
        display: block;
        z-index: 999;
    }
    .navLink-container {
        display: flex;
        position: relative;
        flex-direction: column;
        width: auto;
    }
    .ul-container{
        height: 150px;
    }
    li a {
        color: var(--color-primary-50) !important;
        padding-left: 5%;
    }

    .selected-item {
        background-color: var(--color-primary-50);
        border-radius: 15px;
    }
    
    .selected-item-link{
        color: white !important;
    }
    li a {
        color: var(--color-primary-50) !important;
        padding-left: 5%;
    }

    .selected-item {
        background-color: var(--color-primary-50);
        border-radius: 15px;
    }
    
    .selected-item-link{
        color: white !important;
    }
    .navbar1 {
        padding: 1rem 5%;
        background: var(--bg-dark-green);
        padding-top: 0rem;
        display: none;
    }

    .navbar1.active {
        width: 100vw;
        display: block;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 100vh;
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: auto;
    }

    .navbar1 a {
        display: block;
        font-size: 1rem;
        margin: 1rem 0;
        color: #fff;
    }

    .active {
        border-bottom: none;
    }
}