.next-image {
  position: absolute;
  top: 50%;
  right: 3%;
  cursor: pointer;
  border-radius: 50%;
}

.next-image:hover {
  background-color: grey;
  transition: all 1s;
}
.slider-background-image {
  height: 105vh;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  margin: 15px;
  transition: opacity 1s ease-in-out;
}

.slider-container {
  text-align: center;
  position: relative;
}

.slider_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
}

.slider-item-title {
  color: #f4f84e;
  font-size: 64px;
  line-height: 64px;
  font-weight: 100;
  font-family: "ArgentCF";
  margin-bottom: 5%;
}
.slider-item-description {
  color: #f4f84e;
  font-size: 18px;
  font-weight: 100;
  font-family: "ProximaNova";
  line-height: 30px;
}

.button-slider-item {
  all: unset;
  background-color: #dad1fc;
  padding: 1rem;
  color: #315038;
  border-radius: 7px;
  font-family: "ProximaNova";
  line-height: 20px;
  margin-top: 30px;
}

.story-line {
  position: absolute;
  top: -20;
  left: 0;
  height: 4px;
  background-color: red;
  transition: width 0.5s ease-in-out;
}

.story-text {
  font-size: 18px;
  margin-top: 30px;
  font-family: "Arial", sans-serif;
}

@media screen and (max-width: 600px) {
  .slider-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2rem;
    margin-top: 2rem;
    height: 80vh;
  }
  .slider_item {
    width: 80%;
  }
  .slider-item-title {
    color: #f4f84e;
    font-size: 50px;
    line-height: 64px;
    font-weight: 100;
    font-family: "ArgentCF-Thin";   
    font-style: italic;
  }

  .slider-item-description {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    font-family: "PoximaNova";
  }

  .button-slider-item {
    all: unset;
    margin-top: 2rem;
    background-color: #dad1fc;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #315038;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    font-family: "ProximaNova";
    line-height: 20px;
    cursor: pointer !important;
    border-radius: 5px;
  }
  .slider-container > img{
    display: none;
  }
}
