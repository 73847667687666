.filterView {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.class-details-container {
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
}

.class_details_card {
	border-radius: 15px;
    cursor: pointer;   
}

.class_details_sub_container {
	padding: 3rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: var(--bg-light-salmmon);
}

.class_details_seat_card {
	margin : 0 1rem 0 0 !important;
}


.class_details_book_btn {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: var(--deep-green);
   
    padding: 1rem 3rem;
    width: 100%;
    margin: 0 0%;
    color: var(--highlight);
    text-align: center;
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 16.2px */
    text-transform: uppercase;
}

.service_details_img {
    width: 300px;
}

@media (min-width: 641px) and (max-width: 1024px) {
    .filterView {
        display: block;
    }
}

@media (max-width: 640px) {
	.filterView {
		display: block;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
        width: 100%;
	}

    .class-details-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .service_details_img {
        width: 100%;
    }
}