.react-dropdown-select {
	border: none !important;
	border-bottom: 2px solid green !important;
	outline: 0 !!important;
}

.react-dropdown-select-dropdown-handle {
	margin: 5px 0 0 5px;
}

.react-dropdown-select-dropdown-handle svg {
	color: green;
}

@media (max-width: 992px) {
	.react-dropdown-select-item {
		display: block !important;
	}
}