.user_profile {
	cursor: pointer;
}

.user-pic {
	
	width: 35px;
	height: 25px;
	border-radius: 50%;
}

.user_name {
	font-size: 14px;
}

.sub-menu-wrap {
	z-index: 999;
	position: absolute;
	top: 55px;
	right: 8%;
	width: 250px;
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.5s;
	z-index: 1000;
	font-size: 14px;
}

.sub-menu-wrap.open-menu {
	max-height: 500px; 
}

.sub-menu {
	margin: 10px;
	border-radius: 8px;
}

.user-info {
	display: flex1;
	align-items: center;

}

.user-info h2{
	font-weight: 500;
}

.user-info img {
	width: 30px;
	border-radius: 50%;
	margin-right: 15px;
}

.sub-menu hr {
	border: 0;
	width: 1px;
	width: 100%;
	background: #ccc;
	margin: 15px 0 10px;
}

.sub-menu-link {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #525252;
	margin: 12px 0;
}

.sub-menu-link p {
	width: 100%;
}

.sub-menu-link img {
	width: 30px;
	border-radius: 50%;
	margin-right: 15px;
}

.sub-menu-link span {
	font-size: 18px;
	transition: transform 0.5s;
}

.sub-menu-link:hover{
	color: #D38C33;
}

.sub-menu-link:hover span {
	transform: translateX(5px);
}

.sub-menu-link:hover p {
	font-weight: 600;
}

.white {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
}

@media screen and (max-width: 820px) {
	.user-pic {
		width: 40px;
		cursor: pointer;
	}

	.sub-menu-wrap {
		top: 10px;
		font-size: 14px;
	}
}

@media screen and (max-width: 640px) {
	.sub-menu-wrap {
		width: auto;
		top: 50px;
		right: 30px;
		font-size: 14px;
	}	

	.user-pic {
		width: 30px;
		height: 30px
	}
}

