.catalyst-details-feature-container {
	display: flex;
	text-align: center;
	/*grid-template-columns: 1fr 1fr 1fr 1fr;
   	grid-template-columns: repeat(4, 1fr);
   	*/
  	align-items: center;
    gap: 1rem;
    position: relative;
    flex-wrap: wrap;
    overflow: auto;
}

.catalyst-details-feature-card {
	background-color: #DEE2E6;
	border-radius: 40px;
	padding: .5rem .5rem;
}

.catalyst-details-feature-card div {
	min-width: 150px;
	padding-left: 1rem;
	padding-right: 1rem;
}

.catalyst-details-feature-card .feature-text {
	opacity: .7;
	font-size: .9em;
}

.catalyst-details-feature-card-img {
	width: 50px;
	height: 50px;
	padding: 5px;
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {

}

@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
	.catalyst-details-feature-container {
		
	   	grid-template-columns: repeat(2, 1fr);
	  
	    gap: .3rem;
	}
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
	.catalyst-details-feature-container {
	   	grid-template-columns: repeat(2, 1fr);
	    gap: .3rem;
	}
}

@media screen and (min-device-width: 100px) and (max-device-width: 480px) {
	.catalyst-details-feature-container {
		
	   	grid-template-columns: repeat(2, 1fr);
	  
	    gap: .3rem;
	}

	.catalyst-details-feature-card {
		background-color: #DEE2E6;
		border-radius: 40px;
		padding: 0rem 0rem;
	}

}