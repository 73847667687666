.details-grid {
	display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-60);
}

.ticket-grid {
	display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--spacing-60);
}

.details-picture-grid {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	gap: 24px;
	align-self: stretch;
	flex-wrap: wrap;
}

.category_container {
	display: flex;
	min-width: 120px;
	min-height: 48px;
	padding: var(--spacing-30);
	justify-content: center;
	align-items: center;
	gap: var(--spacing-30);
	border-radius: var(--spacing-20);
	border: 1px solid var(--color-primary-50);
	background: var(--color-secondary-90);
	color: var(--color-primary-50);
}

@media (min-width: 1120px) and (max-width: 1180px) {
	.details-picture-grid {
		gap: 6px;
	}
}

@media (min-width: 641px) and (max-width: 1024px) {
    .details-grid {
	    grid-template-columns: repeat(1, 1fr);
	}

	.ticket-grid {
	    grid-template-columns: repeat(3, 1fr);
	}

	.details-picture-grid {
		gap: 6px;
	}
}

@media (max-width: 640px) {
	.details-grid, .ticket-grid {
	    grid-template-columns: repeat(1, 1fr);
	}
}