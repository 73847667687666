.footer {
    display: flex;
    background-color: #DBD1FF;
    justify-content: space-between; 
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.footer-sub-container {
    padding: 5rem  8vw;
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    width: 100%;
}

.community_padding {
    padding: 2rem 3rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.footer ul {
    padding-left: 0px;
}

.footer ul li {
    padding: 3px 0;
    color: var(--white-text);
}

.footer a:hover, .footer a:visited {
    opacity: .5;
    text-decoration: none;
}

.footer ul.none li a {
    color: var(--color-primary-50);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.footer_text_layout > div {
    display: flex;
    flex-direction: column;
    color: var(--color-primary-100);
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}

.footer .MuiFormControl-root {
    width: 100%
}

.footer_padding {
    padding: 1rem;
}

.footer .MuiInputBase-input, .footer label {
    color: var(--color-neutral-100) !important;
}

.footer_white_text_font {
    font-size: 1.25rem;
}

@media screen and (max-width: 1100px) {
    .footer {
        flex-direction: column;
    }

    .footer-sub-container { 
        padding: 5rem  3vw;
    }

    .community_padding {
        width: 100%;
        padding: auto 10rem;
    }
    
    .footer_white_text_font {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 690px) {
    .footer {
        flex-direction: column;
    }

    .footer-sub-container { 
        padding: 5rem  3vw;
    }

    .footer-sub-container {
        flex-direction: column;
        padding: 2rem  8vw;
    }

    .community_padding {
        width: 100%;
        padding: auto 10rem;
    }

    .footer_white_text_font {
        font-size: 1.2rem;
    } 
}