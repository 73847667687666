.radio-card { 
	min-width: 150px !important;
}

.radio-card label {
	
	padding: 1.5rem 0rem;
	border-radius: 8px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.radio_label_container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
}


.radio_label_container .day_item {
	background-color: #F4F84E;
	width: 100%;
}

.radio-card input:checked + label {
	/*background-color : #F2D7C5;*/
}

.dot {
	font-size: 6px;
	color: #4E705A;
}

.non-selected-dot {
	font-size: 6px;
	color: #FDF6EE;
}

@media (max-width: 982px) {
	.radio-card {
		background-color: var(--highlight);
		width: 100%;
		border-radius: 8px;
	}

	.radio-card label {
		padding: .5rem;
		border-radius: 8px;
		width: 100%;
		
	}

	.radio_label_container {
		flex-direction: column-reverse;
	}
}