.growth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.growth-description {
  width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 610px;
}
.growth-image {
  background-image: url("../../assets/growth.svg");
  height: 90vh;
  background-repeat: no-repeat;
  width: 700px;
  background-size: contain;
  background-position: center;
  border-radius: 17px;
}

.growth-title {
  font-family: "ProximaNova";
  font-weight: 500;
  color: #315038;
}

.growth-title-about {
  font-family: "ArgentCF";
  font-weight: 500;
  font-style: italic;
  text-align: center;
  color: #315038;

  font-size: 48px;
  line-height: 50.18px;
}

.growth-title-description {
  font-family: "ProximaNova";
  text-align: center;
  color: #315038;
  font-size: 16px;
}

.growth-title-mini-title {
  font-family: "ProximaNova";
  text-align: center;
  color: #315038;
  font-weight: 700;
}
.button-slider-item-growth {
  all: unset;
  background-color: #dad1fc;
  padding: 1rem;
  color: #315038;
  border-radius: 5px;
  font-family: "ProximaNova";
  line-height: 20px;
  cursor: pointer !important;
}
.button-slider-item-growth:hover {
  background-color: #dad1fc;
}

@media (400px <= width <= 1000px )  {
  .growth-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .growth-image {
    background-image: url("../../assets/responsive-images/growth-responsive.png");
    background-size: contain;
    width: 90%;
    height: 90vh;
    margin-top: -5rem;
  }

  .growth-description {
    margin-top: 2rem;
  }

  .growth-title {
    font-family: "ProximaNova";
    font-weight: 500;
    font-size: 20px;
    color: #315038;
  }

  .growth-title-about {
    font-family: "ArgentCF-Thin";
    font-weight: 300;
    font-style: italic;
    font-size: 30px;
    color: #315038;
    width: 370px;
    line-height: 40px;
 
  }

  .growth-title-description {
    font-family: "ProximaNova";
    text-align: center;
    color: #315038;
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
    margin-top: 2rem;
    width: 400px;
  }

  .growth-title-mini-title {
    font-family: "ProximaNova";
    text-align: center;
    color: #315038;
    font-weight: 700;
    font-size: 28px;
    width: 400px;
  }
  .button-slider-item-growth {
    all: unset;
    margin-top: 2rem;
    background-color: #dad1fc;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #315038;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    font-family: "ProximaNova";
    line-height: 20px;
    cursor: pointer !important;
    border-radius: 5px;
  }
}
