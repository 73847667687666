.btn-group {
    background-color: var(--dark-green);
    color: #fff;
    display: flex !important;
}

.btn-group .btn {
    color: #fff;
    border: none;
    font-size: 12px;
    transition: all 0.3s ease;
    padding: 8px 15px;
    opacity: .8;
    margin: 2px;
}

.btn-group .btn:hover {
    color: #fff !important;
    border: none;
}

.btn-check:checked + .btn {
    background-color: #fff !important;
    color: green !important;
    border: none;
    transition: all 1s ease;
    border-radius: 6px !important;
    opacity: 1;

}